/* eslint-disable react/no-unstable-nested-components */
import type { ExportableToCSV, SortingState } from '@meterup/common';
import type { ForwardedRef, RefObject } from 'react';
import {
  Badge,
  Button,
  EmptyState,
  Icon,
  ManufacturerIcon,
  PaneContent,
  space,
  SpeedLockup,
  Text,
  Tooltip,
} from '@meterup/atto';
import {
  AutoTable,
  expectDefinedOrThrow,
  getManufacturerIconName,
  ResourceNotFoundError,
  useOuiDataMap,
} from '@meterup/common';
import { capitalize } from 'lodash-es';
import { DateTime } from 'luxon';
import { Suspense, useCallback, useMemo, useRef } from 'react';

import type {
  NetworkClient,
  NetworkClientOptions,
} from '../../../../../hooks/networkClients/useNetworkClients';
import { Box } from '../../../../../components/Box';
import {
  healthVariantIcon,
  healthVariantText,
  wirelessScoreVariant,
} from '../../../../../components/Clients/utils';
import { NoValue } from '../../../../../components/NoValue';
import { PaneContentFallback } from '../../../../../components/Placeholders/AppLoadingFallback';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useNetwork } from '../../../../../hooks/useNetworkFromPath';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import {
  clientManufacturer,
  clientNameOrNull,
  defaultSort,
  formatRadioBand,
  formatUsage,
  getClientConnectionStatus,
  getClientSortKey,
  isOnline,
  isWireless,
} from '../../../../../utils/clientLists';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';
import { ucfirst } from '../../../../../utils/strings';
import { Filters } from './clients.constants';
import { useFilteredNetworkClients } from './clients.hooks';

const builder = createColumnBuilder<NetworkClient>();

function EmptyStates({
  clientCount,
  filteredClientCount,
  onResetFilters,
  emptyStateHeading = 'No clients on this network',
}: {
  clientCount: number;
  filteredClientCount: number;
  onResetFilters: () => void;
  emptyStateHeading?: string;
}) {
  if (clientCount === 0) {
    return <EmptyState icon="client" heading={emptyStateHeading} />;
  }

  if (filteredClientCount === 0) {
    return (
      <EmptyState
        icon="filter"
        heading="Your filter returned no results"
        action={
          <Button icon="minus" arrangement="leading-icon" onClick={onResetFilters}>
            Reset filters
          </Button>
        }
      />
    );
  }
  return null;
}

function ClientsListTable({
  additionalClientPredicate = () => true,
  networkClientOptions = {},
  tableContainerRef,
  tableRef,
  emptyStateHeading,
}: {
  additionalClientPredicate?: (networkClients: NetworkClient) => boolean;
  networkClientOptions?: NetworkClientOptions;
  tableContainerRef: RefObject<HTMLDivElement>;
  tableRef?: ForwardedRef<ExportableToCSV>;
  emptyStateHeading?: string;
}) {
  const params = Nav.useRegionParams('drawer', paths.drawers.ClientSummary2Page);
  const network = useNetwork();
  expectDefinedOrThrow(network, new ResourceNotFoundError('network not found'));
  const closeDrawer = useCloseDrawerCallback();
  const { clients } = useFilteredNetworkClients({
    networkClientOptions,
    network,
  });
  const filteredClients = Filters()
    .useCurrentPredicate(clients)
    .filter(additionalClientPredicate) as NetworkClient[];
  const companyName = useCurrentCompany();

  const sortedClients = useMemo(() => filteredClients.toSorted(defaultSort), [filteredClients]);
  const ouiData = useOuiDataMap();

  // const navigate = useNavigate();

  // const { state } = useCommand();
  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const resetFilter = Filters().useResetCallback();

  // TODO
  // useRegisterCommands([
  //   state.nodeFactory.action({
  //     id: 'view-wireless-info',
  //     display: 'View wireless info',
  //     label: 'View wireless info',
  //     priority: Priority.Low,
  //     icon: 'qrcode',
  //     onSelect() {
  //       navigate(
  //         makeDrawerLink(window.location, paths.drawers.AddClient2Page, {
  //           companyName,
  //           networkSlug,
  //         }),
  //       );
  //     },
  //   }),
  // ]);

  const rowSelected = useCallback(
    (row: NetworkClient) => params?.macAddress === row.macAddress,
    [params?.macAddress],
  );

  const columns = useMemo(
    () => [
      builder.data(
        (d) => `${ucfirst(getClientConnectionStatus(d))}${isWireless(d) ? ' Wireless' : ''}`,
        {
          id: 'status',
          header: () => <Icon icon="question" size={space(16)} />,
          meta: {
            alignment: 'center',
            width: 48,
            tooltip: {
              contents: 'Status',
            },
          },
          cell: (p) => (
            <Badge
              arrangement="hidden-label"
              variant={isOnline(p.row) ? 'positive' : 'neutral'}
              icon={isWireless(p.row) ? 'wifi' : 'wired'}
              size="small"
              ends="pill"
            >
              {isOnline(p.row) ? 'Online' : 'Offline'}
            </Badge>
          ),
        },
      ),
      builder.data((row) => clientManufacturer(row, ouiData), {
        id: 'manufacturer',
        header: () => <Icon icon="question" size={space(16)} />,
        meta: {
          alignment: 'center',
          width: 48,
          tooltip: {
            contents: 'Manufacturer',
          },
        },
        cell: ({ value }) => (
          <Tooltip contents={capitalize(value)}>
            {/* This div (or some DOM element) necessary for the tooltip to work (?) */}
            <div>
              <ManufacturerIcon icon={getManufacturerIconName(value ?? '')} size={space(16)} />
            </div>
          </Tooltip>
        ),
      }),
      builder.data((row) => getClientSortKey(row), {
        id: 'name',
        header: 'Name',
        meta: {
          isLeading: true,
        },
        cell: (p) =>
          clientNameOrNull(p.row) ? <Text>{clientNameOrNull(p.row)}</Text> : <Text>Unnamed</Text>,
      }),
      builder.data((row) => formatUsage(row.totalTxBytes ?? 0), {
        id: 'usage-download',
        header: 'Download (MB)',
        cell: (p) => (
          <SpeedLockup
            direction="download"
            tooltip="Download in megabytes"
            value={formatUsage(p.row.totalTxBytes ?? 0)}
          />
        ),
      }),
      builder.data((row) => formatUsage(row.totalRxBytes ?? 0), {
        id: 'usage-upload',
        header: 'Upload (MB)',
        cell: (p) => (
          <SpeedLockup
            direction="upload"
            tooltip="Upload in megabytes"
            value={formatUsage(p.row.totalRxBytes ?? 0)}
          />
        ),
      }),
      builder.data((row) => row.ip!, {
        id: 'ip',
        header: 'IP',
        cell: (p) => <Text family="monospace">{p.value}</Text>,
      }),

      builder.data((row) => row.macAddress!, {
        id: 'mac',
        header: 'MAC',
        cell: (p) => <Text family="monospace">{p.value}</Text>,
      }),
      builder.data((row) => row.radioBand, {
        id: 'band',
        header: 'Radio band',
        cell: (p) =>
          isWireless(p.row) ? (
            <Badge ends="card" size="small">
              {formatRadioBand(p.value)}
            </Badge>
          ) : (
            <NoValue />
          ),
      }),
      builder.data((d) => (d?.signal || 0).toFixed(0), {
        id: 'dbm',
        header: 'dBm',
        meta: { maxWidth: 80 },
        cell: (p) =>
          isWireless(p.row) && p.row.signal ? (
            <Badge
              arrangement="leading-icon"
              variant={(p.row?.signal || 0) > -74 ? 'positive' : 'negative'}
              size="small"
              ends="pill"
            >
              {p.value}
            </Badge>
          ) : (
            <NoValue />
          ),
      }),
      builder.data((d) => (d?.wirelessScore || 0).toFixed(0), {
        id: 'score',
        header: 'Wireless health',
        meta: {
          maxWidth: 112,
          tooltip: {
            contents:
              'The wireless health is calculated using internal client metrics like PHY rates, packet retry ratios and signal strength.',
          },
        },
        cell: (p) => {
          if (p.row.wirelessScore) {
            const variant = wirelessScoreVariant(p.row.wirelessScore);

            return (
              <Badge
                arrangement="leading-icon"
                ends="pill"
                icon={healthVariantIcon(variant)}
                size="small"
                variant={variant}
              >
                {healthVariantText(variant)}
              </Badge>
            );
          }
          return <NoValue />;
        },
      }),
      builder.data((row) => (isOnline(row) ? row.lastSeen || '-' : '-'), {
        id: 'last-seen',
        header: 'Last seen',
        meta: {
          alignment: 'end',
        },
        cell: (p) => (
          <Text family="monospace" css={{ truncate: true }}>
            {DateTime.fromISO(p.value).toRelative()}
          </Text>
        ),
      }),
    ],
    [ouiData],
  );

  return filteredClients?.length ? (
    <AutoTable
      isVirtual
      exportToCSVFilename="clients"
      ref={tableRef}
      tableContainerRef={tableContainerRef}
      columns={columns}
      data={sortedClients}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      getLinkTo={(row) =>
        makeLink(paths.pages.ClientInsightsPage, {
          companyName,
          networkSlug: network.slug,
          macAddress: row.macAddress,
        })
      }
      isRowSelected={rowSelected}
      onRowDeselect={closeDrawer}
    />
  ) : (
    <EmptyStates
      clientCount={clients.length}
      filteredClientCount={filteredClients.length}
      onResetFilters={resetFilter}
      emptyStateHeading={emptyStateHeading}
    />
  );
}

export function ClientsList2(props: {
  additionalClientPredicate?: (networkClients: NetworkClient) => boolean;
  networkClientOptions?: NetworkClientOptions;
  tableRef?: ForwardedRef<ExportableToCSV>;
  emptyStateHeading?: string;
}) {
  const tableContainerRef = useRef(null);
  return (
    <PaneContent ref={tableContainerRef}>
      <Suspense
        fallback={
          <Box css={{ padding: '$20' }}>
            <PaneContentFallback />
          </Box>
        }
      >
        <ClientsListTable tableContainerRef={tableContainerRef} {...props} />
      </Suspense>
    </PaneContent>
  );
}
