import type { ParseParams, ZodSchema } from 'zod';

export const withZodSchema =
  <T>(schema: ZodSchema<T>, params?: Partial<ParseParams>) =>
  (values: T): Partial<Record<keyof T | string, string>> => {
    const result = schema.safeParse(values, params);

    if (result.success) return {};

    return result.error.issues.reduce((acc, curr) => {
      let key = '';
      const { path } = curr;
      if (path.some((item) => typeof item === 'number')) {
        path.forEach((accessor: string | number, idx) => {
          if (typeof accessor === 'number') {
            key += `[${accessor}]`;
          } else if (typeof accessor === 'string' && idx !== 0) {
            key += `.${accessor}`;
          } else {
            key += accessor;
          }
        });
      } else {
        key = curr.path.join('.');
      }
      return {
        ...acc,
        [key]: curr.message,
      };
    }, {});
  };
