import type { QueryResultField } from '@meterup/graphql';
import { darkThemeSelector, Small, styled } from '@meterup/atto';
import { colors, isDefinedAndNotEmpty } from '@meterup/common';
import { SsidDynamicVlanMode } from '@meterup/common/src/gql/graphql';
import { z } from 'zod';

import type {
  RadiusProfilesQueryQuery,
  SsiDsQueryQuery as SSIDsQueryQuery,
} from '../../../gql/graphql';
import { graphql } from '../../../gql';
import {
  SsidEncryption80211wProtectedManagementFramesMode,
  SsidEncryptionProtocol,
} from '../../../gql/graphql';
import {
  CreateEncryption8021XInputSchema,
  CreateSsidInputSchema,
  SsidBroadcastScheduleIntervalInputSchema,
  UpdateEncryption8021XInputSchema,
  UpdateSsidInputSchema,
} from '../../../gql/zod-types';

export const SSID_NO_VLAN_MESSAGE = 'SSID cannot be enabled until it is assigned to a VLAN.';

function doIntervalsOverlap(interval1: any, interval2: any) {
  const interval1Start = interval1.startDayOfWeek * 24 + interval1.startHour;
  const interval1End = interval1.endDayOfWeek * 24 + interval1.endHour;
  const interval2Start = interval2.startDayOfWeek * 24 + interval2.startHour;
  const interval2End = interval2.endDayOfWeek * 24 + interval2.endHour;
  return !(interval1End <= interval2Start || interval2End <= interval1Start);
}

export const createSSIDInputSchema = CreateSsidInputSchema.extend({
  vlanUUID: z.string(),
  isGuest: z.boolean().optional(),
  hasPassword: z.boolean(),
  hasBroadcastSchedule: z.boolean(),
  passwordRotationEnabled: z.boolean(),
  frameProtectionEnabled: z.boolean(),
  ssid: z.string().nonempty({ message: 'Please provide an SSID.' }),
  dynamicVLANEnabled: z.boolean(),
  broadcastSchedule: z
    .array(SsidBroadcastScheduleIntervalInputSchema.extend({ id: z.string() }))
    .nullish(),
})
  .superRefine(({ broadcastSchedule }, ctx) => {
    if (!broadcastSchedule) return;

    for (let i = 0; i < broadcastSchedule.length; i += 1) {
      if (
        broadcastSchedule[i].startDayOfWeek === broadcastSchedule[i].endDayOfWeek &&
        broadcastSchedule[i].startHour === broadcastSchedule[i].endHour
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`broadcastSchedule.${i}.endHour`],
          message: 'Interval has no duration',
        });
      }

      if (i < broadcastSchedule.length - 1) {
        for (let j = i + 1; j < broadcastSchedule.length; j += 1) {
          if (doIntervalsOverlap(broadcastSchedule[i], broadcastSchedule[j])) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`broadcastSchedule.${j}.startDayOfWeek`],
              message: 'Intervals cannot overlap',
            });
          }
        }
      }
    }
  })
  .superRefine(({ dynamicVLANEnabled, dynamicVLANMode, isEnabled, vlanUUID }, ctx) => {
    const vlanRequired = !dynamicVLANEnabled || dynamicVLANMode === SsidDynamicVlanMode.Enabled;

    if (vlanRequired && isEnabled && !vlanUUID) {
      for (const path of ['isEnabled', 'vlanUUID']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [path],
          message: dynamicVLANEnabled
            ? 'Fallback VLAN required when dynamic vlan mode is Enabled.'
            : SSID_NO_VLAN_MESSAGE,
        });
      }
    }
  })
  .refine(
    ({ hasPassword, encryptionProtocol, password }) =>
      !hasPassword ||
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa2 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Transition) ||
      isDefinedAndNotEmpty(password),
    {
      message: 'Password is required.',
      path: ['password'],
    },
  )
  .refine(
    ({ hasPassword, encryptionProtocol, primaryEncryption8021XUUID }) =>
      !hasPassword ||
      encryptionProtocol !== SsidEncryptionProtocol.Wpa2Enterprise ||
      isDefinedAndNotEmpty(primaryEncryption8021XUUID),
    {
      message: 'Primary RADIUS profile is required for WPA2-Enterprise.',
      path: ['primaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({ hasPassword, encryptionProtocol, primaryEncryption8021XUUID }) =>
      !hasPassword ||
      encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise ||
      isDefinedAndNotEmpty(primaryEncryption8021XUUID),
    {
      message: 'Primary RADIUS profile is required for WPA3-Enterprise.',
      path: ['primaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({ hasPassword, encryptionProtocol, primaryEncryption8021XUUID }) =>
      !hasPassword ||
      encryptionProtocol !== SsidEncryptionProtocol.OpenMacAuthRadius ||
      isDefinedAndNotEmpty(primaryEncryption8021XUUID),
    {
      message: 'Primary RADIUS profile is required for RADIUS MAC auth.',
      path: ['primaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({
      hasPassword,
      encryptionProtocol,
      primaryEncryption8021XUUID,
      secondaryEncryption8021XUUID,
    }) =>
      !hasPassword ||
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa2Enterprise &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise &&
        encryptionProtocol !== SsidEncryptionProtocol.OpenMacAuthRadius) ||
      !isDefinedAndNotEmpty(primaryEncryption8021XUUID) ||
      primaryEncryption8021XUUID !== secondaryEncryption8021XUUID,
    {
      message: 'Primary and secondary RADIUS profiles cannot be the same.',
      path: ['secondaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({ encryptionProtocol, frameProtectionEnabled }) =>
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa3 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Transition &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise) ||
      frameProtectionEnabled,
    {
      message: '802.11w must be enabled for WPA3.',
      path: ['frameProtectionEnabled'],
    },
  )
  .refine(
    ({ encryptionProtocol, encryption80211wProtectedManagementFramesMode }) =>
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa3 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Transition &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise) ||
      isDefinedAndNotEmpty(encryption80211wProtectedManagementFramesMode),
    {
      message: '802.11w protected management frames must be set for WPA3.',
      path: ['encryption80211wProtectedManagementFramesMode'],
    },
  )
  .refine(
    ({ encryptionProtocol, encryption80211wProtectedManagementFramesMode }) =>
      encryptionProtocol !== SsidEncryptionProtocol.Wpa3 ||
      encryption80211wProtectedManagementFramesMode ===
        SsidEncryption80211wProtectedManagementFramesMode.Required,
    {
      message: '802.11w protected management frames must be set to Required for WPA3-PSK.',
      path: ['encryption80211wProtectedManagementFramesMode'],
    },
  )
  .refine(
    ({ encryptionProtocol, encryption80211wProtectedManagementFramesMode }) =>
      encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise ||
      encryption80211wProtectedManagementFramesMode ===
        SsidEncryption80211wProtectedManagementFramesMode.Required,
    {
      message: '802.11w protected management frames must be set to Required for WPA3-Enterprise.',
      path: ['encryption80211wProtectedManagementFramesMode'],
    },
  );

export const updateSSIDInputSchema = UpdateSsidInputSchema.extend({
  vlanUUID: z.string(),
  isGuest: z.boolean().optional(),
  hasPassword: z.boolean(),
  hasBroadcastSchedule: z.boolean(),
  passwordRotationEnabled: z.boolean(),
  frameProtectionEnabled: z.boolean(),
  ssid: z.string().nonempty({ message: 'SSID cannot be blank.' }),
  dynamicVLANEnabled: z.boolean(),
  broadcastSchedule: z
    .array(SsidBroadcastScheduleIntervalInputSchema.extend({ id: z.string() }))
    .nullish(),
})
  .superRefine(({ broadcastSchedule }, ctx) => {
    if (!broadcastSchedule) return;

    for (let i = 0; i < broadcastSchedule.length; i += 1) {
      if (
        broadcastSchedule[i].startDayOfWeek === broadcastSchedule[i].endDayOfWeek &&
        broadcastSchedule[i].startHour === broadcastSchedule[i].endHour
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [`broadcastSchedule.${i}.endHour`],
          message: 'Interval has no duration',
        });
      }

      if (i < broadcastSchedule.length - 1) {
        for (let j = i + 1; j < broadcastSchedule.length; j += 1) {
          if (doIntervalsOverlap(broadcastSchedule[i], broadcastSchedule[j])) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [`broadcastSchedule.${j}.startDayOfWeek`],
              message: 'Intervals cannot overlap',
            });
          }
        }
      }
    }
  })
  .superRefine(({ dynamicVLANEnabled, dynamicVLANMode, isEnabled, vlanUUID }, ctx) => {
    const vlanRequired = !dynamicVLANEnabled || dynamicVLANMode === SsidDynamicVlanMode.Enabled;

    if (vlanRequired && isEnabled && !vlanUUID) {
      for (const path of ['isEnabled', 'vlanUUID']) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [path],
          message: dynamicVLANEnabled
            ? 'Fallback VLAN required when dynamic vlan mode is Enabled.'
            : SSID_NO_VLAN_MESSAGE,
        });
      }
    }
  })
  .refine(
    ({ hasPassword, encryptionProtocol, password }) =>
      !hasPassword ||
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa2 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Transition) ||
      isDefinedAndNotEmpty(password),
    {
      message: 'Password is required.',
      path: ['password'],
    },
  )
  .refine(
    ({ hasPassword, encryptionProtocol, primaryEncryption8021XUUID }) =>
      !hasPassword ||
      encryptionProtocol !== SsidEncryptionProtocol.Wpa2Enterprise ||
      isDefinedAndNotEmpty(primaryEncryption8021XUUID),
    {
      message: 'Primary RADIUS profile is required for WPA2-Enterprise.',
      path: ['primaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({ hasPassword, encryptionProtocol, primaryEncryption8021XUUID }) =>
      !hasPassword ||
      encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise ||
      isDefinedAndNotEmpty(primaryEncryption8021XUUID),
    {
      message: 'Primary RADIUS profile is required for WPA3-Enterprise.',
      path: ['primaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({ hasPassword, encryptionProtocol, primaryEncryption8021XUUID }) =>
      !hasPassword ||
      encryptionProtocol !== SsidEncryptionProtocol.OpenMacAuthRadius ||
      isDefinedAndNotEmpty(primaryEncryption8021XUUID),
    {
      message: 'Primary RADIUS profile is required for RADIUS MAC auth.',
      path: ['primaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({
      hasPassword,
      encryptionProtocol,
      primaryEncryption8021XUUID,
      secondaryEncryption8021XUUID,
    }) =>
      !hasPassword ||
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa2Enterprise &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise &&
        encryptionProtocol !== SsidEncryptionProtocol.OpenMacAuthRadius) ||
      !isDefinedAndNotEmpty(primaryEncryption8021XUUID) ||
      primaryEncryption8021XUUID !== secondaryEncryption8021XUUID,
    {
      message: 'Primary and secondary RADIUS profiles cannot be the same.',
      path: ['secondaryEncryption8021XUUID'],
    },
  )
  .refine(
    ({ encryptionProtocol, frameProtectionEnabled }) =>
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa3 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Transition &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise) ||
      frameProtectionEnabled,
    {
      message: '802.11w must be enabled for WPA3.',
      path: ['frameProtectionEnabled'],
    },
  )
  .refine(
    ({ encryptionProtocol, encryption80211wProtectedManagementFramesMode }) =>
      (encryptionProtocol !== SsidEncryptionProtocol.Wpa3 &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Transition &&
        encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise) ||
      isDefinedAndNotEmpty(encryption80211wProtectedManagementFramesMode),
    {
      message: '802.11w protected management frames must be set for WPA3.',
      path: ['encryption80211wProtectedManagementFramesMode'],
    },
  )
  .refine(
    ({ encryptionProtocol, encryption80211wProtectedManagementFramesMode }) =>
      encryptionProtocol !== SsidEncryptionProtocol.Wpa3 ||
      encryption80211wProtectedManagementFramesMode ===
        SsidEncryption80211wProtectedManagementFramesMode.Required,
    {
      message: '802.11w protected management frames must be set to Required for WPA3-PSK.',
      path: ['encryption80211wProtectedManagementFramesMode'],
    },
  )
  .refine(
    ({ encryptionProtocol, encryption80211wProtectedManagementFramesMode }) =>
      encryptionProtocol !== SsidEncryptionProtocol.Wpa3Enterprise ||
      encryption80211wProtectedManagementFramesMode ===
        SsidEncryption80211wProtectedManagementFramesMode.Required,
    {
      message: '802.11w protected management frames must be set to Required for WPA3-Enterprise.',
      path: ['encryption80211wProtectedManagementFramesMode'],
    },
  );

export type SSIDsQueryResult = QueryResultField<SSIDsQueryQuery, 'ssidsForNetwork'>;
export type ValidCreateSSIDParams = z.infer<typeof createSSIDInputSchema>;
export type ValidUpdateSSIDParams = z.infer<typeof updateSSIDInputSchema>;
export type ValidSSIDParams = ValidCreateSSIDParams | ValidUpdateSSIDParams;

export type RadiusProfilesQueryResult = QueryResultField<
  RadiusProfilesQueryQuery,
  'encryption8021XsForNetwork'
>;

export const CreateRadiusProfileInputSchema = CreateEncryption8021XInputSchema.extend({
  label: z.string().nonempty({ message: 'Label cannot be blank' }),
  authServerIPAddress: z.string().nonempty({ message: 'Auth server IP address cannot be blank.' }),
  authServerPort: z.number().gt(0, { message: 'Auth server port must be greater than 0.' }),
  authServerSecret: z.string().nonempty({ message: 'Auth server secret cannot be blank.' }),
}).superRefine((obj, ctx) => {
  const anySpecified =
    isDefinedAndNotEmpty(obj.accountingServerIPAddress) ||
    isDefinedAndNotEmpty(obj.accountingServerSecret);

  if (anySpecified) {
    if (!isDefinedAndNotEmpty(obj.accountingServerIPAddress)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'IP address cannot be blank.',
        path: ['accountingServerIPAddress'],
      });
    }

    if (isDefinedAndNotEmpty(obj.accountingServerPort) && obj.accountingServerPort <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Port must be greater than 0.',
        path: ['accountingServerPort'],
      });
    }

    if (!isDefinedAndNotEmpty(obj.accountingServerSecret)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Secret cannot be blank.',
        path: ['accountingServerSecret'],
      });
    }
  }
});

export const UpdateRadiusProfileInputSchema = UpdateEncryption8021XInputSchema.extend({
  label: z.string().nonempty({ message: 'Label cannot be blank' }),
  authServerIPAddress: z.string().nonempty({ message: 'Auth server IP address cannot be blank.' }),
  authServerPort: z.number().gt(0, { message: 'Auth server port must be greater than 0.' }),
  authServerSecret: z.string().nonempty({ message: 'Auth server secret cannot be blank.' }),
}).superRefine((obj, ctx) => {
  const anySpecified =
    isDefinedAndNotEmpty(obj.accountingServerIPAddress) ||
    isDefinedAndNotEmpty(obj.accountingServerSecret);

  if (anySpecified) {
    if (!isDefinedAndNotEmpty(obj.accountingServerIPAddress)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'IP address cannot be blank.',
        path: ['accountingServerIPAddress'],
      });
    }

    if (isDefinedAndNotEmpty(obj.accountingServerPort) && obj.accountingServerPort <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Port must be greater than 0.',
        path: ['accountingServerPort'],
      });
    }

    if (!isDefinedAndNotEmpty(obj.accountingServerSecret)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Secret cannot be blank.',
        path: ['accountingServerSecret'],
      });
    }
  }
});

export type ValidCreateRadiusProfileParams = z.infer<typeof CreateRadiusProfileInputSchema>;
export type ValidUpdateRadiusProfileParams = z.infer<typeof UpdateRadiusProfileInputSchema>;
export type ValidRadiusProfileParams =
  | ValidCreateRadiusProfileParams
  | ValidUpdateRadiusProfileParams;

export const SSIDsQuery = graphql(`
  query SSIDsQuery($networkUUID: UUID!) {
    ssidsForNetwork(networkUUID: $networkUUID) {
      UUID
      ssid
      clientSteeringAssociationSteeringIsEnabled
      clientSteeringPostassociationSteeringIsEnabled
      description
      is80211axEnabled
      isEnabled
      isGuest
      isHidden
      isIsolateClientsEnabled
      encryptionProtocol
      password
      isBand2GAllowed
      band2GMinimumBasicRateKbps
      isBand5GAllowed
      passwordRotationCadence
      passwordRotationDayOfMonthLocal
      passwordRotationDayOfWeekLocal
      passwordRotationHourOfDayLocal
      passwordLastRotatedAt
      passwordNextRotationAt
      band5GMinimumBasicRateKbps
      isEnabledForAllAccessPoints
      roaming80211rIsEnabled
      roaming80211kIsEnabled
      roaming80211vIsEnabled
      encryption80211wProtectedManagementFramesMode
      hs20HasProfile
      hs20Enabled
      hs20OperatorNames
      hs20VenueNames
      hs20VenueGroup
      hs20VenueType
      hs20AccessNetworkType
      hs20RoamingConsortiumOIs
      hs20DomainNames
      hs20NaiRealms
      hs20MccMncPairs
      dynamicVLANMode
      radiusCoaEnabled
      radiusCoaPort
      primaryEncryption8021X {
        UUID
      }
      secondaryEncryption8021X {
        UUID
      }
      enabledAccessPointVirtualDevices {
        UUID
      }
      disabledAccessPointVirtualDevices {
        UUID
      }
      vlan {
        UUID
        name
      }
      broadcastSchedule {
        startDayOfWeek
        startHour
        startMinute
        endDayOfWeek
        endHour
        endMinute
      }
    }
  }
`);

export const Hs20AccessNetworkTypes = graphql(`
  query Hs20AccessNetworkTypes {
    hs20ValidTypes {
      accessNetworkTypes {
        type
        description
      }
      venueTypes {
        group
        type
        description
      }
      eapMethods {
        method
        description
        mostUsed
      }
      authParams {
        param
        description
        validValues {
          value
          description
        }
      }
    }
  }
`);

export const CreateSSIDMutation = graphql(`
  mutation CreateSSIDMutation($networkUUID: UUID!, $input: CreateSSIDInput!) {
    createSSID(networkUUID: $networkUUID, input: $input) {
      UUID
    }
  }
`);

export const UpdateSSIDMutation = graphql(`
  mutation UpdateSSIDMutation($uuid: UUID!, $input: UpdateSSIDInput!) {
    updateSSID(uuid: $uuid, input: $input) {
      UUID
    }
  }
`);

export const DeleteSSIDMutation = graphql(`
  mutation DeleteSSIDMutation($uuid: UUID!) {
    deleteSSID(uuid: $uuid) {
      UUID
    }
  }
`);

export const copySSIDsMutation = graphql(`
  mutation CopySSIDsMutation($networkUUID: UUID!) {
    copySSIDsFromConfig1ToConfig2(networkUUID: $networkUUID) {
      UUID
    }
  }
`);

export const RadiusProfilesQuery = graphql(`
  query RadiusProfilesQuery($networkUUID: UUID!) {
    encryption8021XsForNetwork(networkUUID: $networkUUID) {
      UUID
      label
      authServerIPAddress
      authServerPort
      authServerSecret
      accountingServerIPAddress
      accountingServerPort
      accountingServerSecret
    }
  }
`);

export const CreateRadiusProfileMutation = graphql(`
  mutation CreateRadiusProfileMutation($input: CreateEncryption8021XInput!) {
    createEncryption8021X(input: $input) {
      UUID
    }
  }
`);

export const UpdateRadiusProfileMutation = graphql(`
  mutation UpdateRadiusProfileMutation($uuid: UUID!, $input: UpdateEncryption8021XInput!) {
    updateEncryption8021X(UUID: $uuid, input: $input) {
      UUID
    }
  }
`);

export const DeleteRadiusProfileMutation = graphql(`
  mutation DeleteRadiusProfileMutation($uuid: UUID!) {
    deleteEncryption8021X(UUID: $uuid) {
      UUID
    }
  }
`);

// TODO(apt, 2024-01-30): Greg is going to create a standard version of this that we can use in
//  Atto, but will have it here until then.
export const Divider = styled('div', {
  display: 'flex',
  alignItems: 'center',
  width: '0',
  height: '100%',

  '&:before': {
    content: '',
    position: 'absolute',
    display: 'block',
    width: '1px',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },

    '@notDesktop': {
      height: '$16',
    },

    '@desktop': {
      height: '$12',
    },
  },
});

export const encryptionProtocolHuman = (
  protocol: SSIDsQueryResult['encryptionProtocol'],
  // Using return type to check for exhaustiveness
  // eslint-disable-next-line consistent-return
): string => {
  switch (protocol) {
    case undefined:
    case null:
      return '';
    case SsidEncryptionProtocol.Wpa2:
      return 'WPA2';
    case SsidEncryptionProtocol.Wpa2Enterprise:
      return 'WPA2-Enterprise';
    case SsidEncryptionProtocol.Wpa2Ipsk:
      return 'iPSK';
    case SsidEncryptionProtocol.Wpa3:
      return 'WPA3';
    case SsidEncryptionProtocol.Wpa3Transition:
      return 'WPA3-Transition';
    case SsidEncryptionProtocol.Wpa3Enterprise:
      return 'WPA3-Enterprise';
    case SsidEncryptionProtocol.OpenMacAuthRadius:
      return 'RADIUS MAC auth';
  }
};

export function enterpriseEncryptionProtocol(
  protocol: SSIDsQueryResult['encryptionProtocol'],
): boolean {
  return (
    protocol === SsidEncryptionProtocol.Wpa2Enterprise ||
    protocol === SsidEncryptionProtocol.Wpa3Enterprise ||
    protocol === SsidEncryptionProtocol.OpenMacAuthRadius
  );
}

export function SSIDsCopyDescription() {
  return (
    <>
      Copies <Small family="monospace">wireless.tags</Small> configs. SSIDs (
      <Small family="monospace">service-sets</Small>) in the{' '}
      <Small family="monospace">default</Small> tag are added to all APs,{' '}
      <Small family="monospace">service-sets</Small> in other tags are added to only their
      respective APs.
    </>
  );
}

export type SSID = SSIDsQueryQuery['ssidsForNetwork'][number];
