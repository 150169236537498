import { RoleName } from '../gql/graphql';

// Sorted by highest to lowest role
// Right now each descending role is a strict subset of the previous role
export const AllRoles = [
  RoleName.Operator,
  RoleName.Partner,
  RoleName.CompanyGlobalAdmin,
  RoleName.CompanyNetworkAdmin,
  RoleName.CompanyNetworkAdminReadOnly,
  // For now, don't show these roles in the UI
  // RoleName.CompanyStandardUser,
  // RoleName.CompanyGuest,
];

export function roleNameIsNetworkSpecific(role: RoleName) {
  return role === RoleName.CompanyNetworkAdmin || role === RoleName.CompanyNetworkAdminReadOnly;
}

export function roleToName(role: RoleName | null) {
  switch (role) {
    case RoleName.Operator:
      return 'Operator';
    case RoleName.Partner:
      return 'Partner';
    case RoleName.CompanyGlobalAdmin:
      return 'Company admin';
    case RoleName.CompanyNetworkAdmin:
      return 'Network admin';
    case RoleName.CompanyNetworkAdminReadOnly:
      return 'Network admin (read-only)';
  }
  return 'None';
}

export function isHigherRole(role: RoleName, than: RoleName | null) {
  if (than === null) {
    return true;
  }
  return AllRoles.indexOf(role) <= AllRoles.indexOf(than);
}

export function highestRole(roles: RoleName[]) {
  let highest: RoleName | null = null;
  for (const role of roles) {
    if (isHigherRole(role, highest)) {
      highest = role;
    }
  }
  return highest;
}

export function deletedAtToStatus(deletedAt?: string | null) {
  return deletedAt ? 'Deleted' : 'Active';
}
