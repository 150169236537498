import type { SortingState } from '@tanstack/react-table';
import { Badge, Button, DeviceTarget, EmptyState, Icon, space, Tooltip } from '@meterup/atto';
import {
  AutoTable,
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import { SsidDynamicVlanMode } from '@meterup/common/src/gql/graphql';
import { useGraphQL } from '@meterup/graphql';
import { Link } from 'react-router-dom';

import type { SSIDsQueryResult } from './SSIDsUtils';
import { paths } from '../../../constants';
import { PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useNetwork, useNetworkUUID } from '../../../hooks/useNetworkFromPath';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { useSearchParamsState } from '../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { NoValue } from '../../NoValue';
import { ReactRouterLink } from '../../ReactRouterLink';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { AccessPointsQuery } from '../utils';
import { encryptionProtocolHuman, SSID_NO_VLAN_MESSAGE, SSIDsQuery } from './SSIDsUtils';

const builder = createColumnBuilder<SSIDsQueryResult>();

function SSIDBadge({ badgeText }: { badgeText: string | undefined | null }) {
  if (badgeText) {
    return (
      <Badge size="small" ends="card">
        {badgeText}
      </Badge>
    );
  }
  return <NoValue />;
}

export function SSIDAccessPoints({ ssid }: { ssid: SSIDsQueryResult }) {
  const networkUUID = checkDefinedOrThrow(useNetworkUUID());
  const { hasPermission } = usePermissions();
  const includeUptime = hasPermission(PermissionType.PermNetworkDevicesReadRestricted);
  const allAccessPoints = useGraphQL(AccessPointsQuery, { networkUUID, includeUptime }).data
    ?.virtualDevicesForNetwork;
  expectDefinedOrThrow(allAccessPoints, new ResourceNotFoundError('Unable to load access points'));

  if (ssid.isEnabledForAllAccessPoints) return <span>All</span>;

  const numAccessPoints = ssid.enabledAccessPointVirtualDevices?.length ?? 0;
  return (
    <span>
      {numAccessPoints} / {allAccessPoints.length}
    </span>
  );
}

function VLANCell({ ssid }: { ssid: SSIDsQueryResult }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();

  if (isDefinedAndNotEmpty(ssid.dynamicVLANMode)) {
    const enabled = ssid.dynamicVLANMode === SsidDynamicVlanMode.Enabled;

    return (
      <Tooltip
        contents={
          enabled
            ? `Dynamic VLAN assigned by Radius server with fallback vlan: ${ssid.vlan?.name}`
            : 'Dynamic VLAN enabled'
        }
      >
        <Badge size="small" ends="card" icon="vlan" variant="neutral" arrangement="leading-icon">
          {`Dynamic${enabled ? ` | ${ssid.vlan?.name}` : ''}`}
        </Badge>
      </Tooltip>
    );
  }

  return ssid.vlan ? (
    <DeviceTarget
      as={ReactRouterLink}
      to={makeDrawerLink(window.location, paths.drawers.VLANDrawerPage, {
        companyName,
        networkSlug: network.slug,
        uuid: ssid.vlan?.UUID,
      })}
      icon="vlan"
      wrap={false}
      aria-label={`Go to ${ssid.vlan.name}`}
    >
      {ssid.vlan.name}
    </DeviceTarget>
  ) : (
    <Tooltip contents={SSID_NO_VLAN_MESSAGE}>
      <Badge
        size="small"
        ends="card"
        icon="attention"
        variant="attention"
        arrangement="leading-icon"
      >
        No VLAN
      </Badge>
    </Tooltip>
  );
}

export const ssidColumns = [
  builder.data((ssid) => (ssid.isEnabled ? 'Enabled' : 'Disabled'), {
    enableGlobalFilter: false,
    id: 'ssid-enabled',
    header: () => <Icon icon="checkmark" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Enabled',
      },
    },
    cell: ({ row }) => {
      if (row.isEnabled) {
        return (
          <Tooltip asChild={false} contents="Enabled">
            <Icon
              icon="checkmark"
              color={{
                light: 'iconPositiveLight',
                dark: 'iconPositiveDark',
              }}
              size={space(16)}
            />
          </Tooltip>
        );
      }

      return (
        <Tooltip asChild={false} contents="Disabled">
          <NoValue />
        </Tooltip>
      );
    },
  }),
  builder.data((row) => (row.isHidden ? 'Hidden' : 'Broadcast'), {
    enableGlobalFilter: false,
    id: 'ssid-broadcast',
    header: () => <Icon icon="wifi" size={space(16)} />,
    meta: {
      alignment: 'center',
      width: 40,
      tooltip: {
        contents: 'Broadcast',
      },
    },
    cell: ({ row: ssid }) => {
      if (isDefined(ssid.broadcastSchedule)) {
        return (
          <Tooltip asChild={false} contents="SSID has scheduled intervals where it is broadcasted">
            <Icon
              icon="calendar"
              size={space(16)}
              color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
            />
          </Tooltip>
        );
      }

      if (!ssid.isHidden) {
        return (
          <Tooltip asChild={false} contents="Visible">
            <Icon
              icon="wifi"
              size={space(16)}
              color={{ light: 'iconPositiveLight', dark: 'iconPositiveDark' }}
            />
          </Tooltip>
        );
      }

      return (
        <Tooltip asChild={false} contents="Hidden">
          <Icon
            icon="eye-closed"
            color={{
              light: 'iconNeutralLight',
              dark: 'iconNeutralDark',
            }}
            size={space(16)}
          />
        </Tooltip>
      );
    },
  }),
  builder.data((ssid) => ssid.ssid, {
    id: 'ssid-ssid',
    header: 'SSID',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((ssid) => ssid.encryptionProtocol ?? '', {
    id: 'ssid-encryption',
    header: 'Encryption',
    cell: ({ row: ssid }) => (
      <SSIDBadge badgeText={encryptionProtocolHuman(ssid.encryptionProtocol)} />
    ),
  }),
  builder.data((ssid) => (ssid.isBand2GAllowed ? 'Enabled' : 'Disabled'), {
    id: 'ssid-band2g',
    header: '2.4 GHz',
    meta: {
      alignment: 'center',
      maxWidth: 80,
    },
    cell: ({ row: ssid }) => (
      <Icon
        color={{
          light: ssid.isBand2GAllowed ? 'iconPositiveLight' : 'iconNeutralLight',
          dark: ssid.isBand2GAllowed ? 'iconPositiveDark' : 'iconNeutralDark',
        }}
        icon={ssid.isBand2GAllowed ? 'checkmark' : 'cross'}
        size={space(14)}
      />
    ),
  }),
  builder.data((ssid) => (ssid.isBand5GAllowed ? 'Enabled' : 'Disabled'), {
    id: 'ssid-band5g',
    header: '5 GHz',
    meta: {
      alignment: 'center',
      maxWidth: 80,
    },
    cell: ({ row: ssid }) => (
      <Icon
        color={{
          light: ssid.isBand5GAllowed ? 'iconPositiveLight' : 'iconNeutralLight',
          dark: ssid.isBand5GAllowed ? 'iconPositiveDark' : 'iconNeutralDark',
        }}
        icon={ssid.isBand5GAllowed ? 'checkmark' : 'cross'}
        size={space(14)}
      />
    ),
  }),
  builder.data(
    (ssid) =>
      ssid.isEnabledForAllAccessPoints
        ? 'All'
        : ssid.enabledAccessPointVirtualDevices?.length?.toString() ?? '0',
    {
      id: 'ssid-access-points',
      header: 'Access points',
      cell: ({ row: ssid }) => <SSIDAccessPoints ssid={ssid} />,
    },
  ),
  builder.data((ssid) => ssid.vlan?.name ?? '', {
    id: 'ssid-vlan',
    header: 'VLAN',
    cell: ({ row: ssid }) => <VLANCell ssid={ssid} />,
  }),
];

export default function SSIDsList() {
  const closeDrawer = useCloseDrawerCallback();
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const ssids = useGraphQL(SSIDsQuery, { networkUUID: network.UUID }).data?.ssidsForNetwork;
  expectDefinedOrThrow(ssids, new ResourceNotFoundError('Unable to load SSIDs'));

  const [globalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.SSIDDrawerPage);

  return ssids.length === 0 ? (
    <EmptyState
      icon="ssid"
      heading="No SSIDs"
      action={
        <Button
          as={Link}
          to={makeDrawerLink(window.location, paths.drawers.SSIDCreatePage, {
            companyName,
            networkSlug: network.slug,
          })}
          arrangement="leading-icon"
          icon="plus"
        >
          Add SSID
        </Button>
      }
    />
  ) : (
    <AutoTable
      key="ssid-list"
      columns={ssidColumns}
      data={ssids}
      sortingState={sortingState}
      onChangeSortingState={setSortingState}
      globalFilter={globalFilter}
      getLinkTo={(row) =>
        makeDrawerLink(window.location, paths.drawers.SSIDDrawerPage, {
          uuid: row.UUID,
          companyName,
          networkSlug: network.slug,
        })
      }
      isRowSelected={(row) => row.UUID === drawerParams?.uuid}
      onRowDeselect={closeDrawer}
    />
  );
}
