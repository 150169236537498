import type { z } from 'zod';
import {
  Alert,
  Button,
  CompositeField,
  DrawerContent,
  DrawerFooter,
  FieldContainer,
  HStack,
  Link,
  MultiComboBox,
  MultiComboBoxItem,
  PrimaryField,
  SecondaryField,
  Select,
  SelectItem,
  space,
  Text,
  Textarea,
  TextInput,
  ToggleInput,
} from '@meterup/atto';
import { expectDefinedOrThrow, notify, ResourceNotFoundError } from '@meterup/common';
import {
  getGraphQLErrorMessageOrEmpty,
  makeQueryKey,
  useGraphQL,
  useGraphQLMutation,
} from '@meterup/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik, useFormikContext } from 'formik';
import { get } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';

import type { CreateVlanInput, UpdateVlanInput } from '../../../gql/graphql';
import type { DHCPRule, VLAN, VLANQueryResult } from './utils';
import { DEFAULT_DNS_UPSTREAM_SERVERS, MAX_VLAN_ID, paths } from '../../../constants';
import { ClientAssignmentProtocol, PermissionType } from '../../../gql/graphql';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { useNetwork } from '../../../hooks/useNetworkFromPath';
import { NosFeature, useNosFeatureEnabled } from '../../../hooks/useNosFeatures';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { usePermissions } from '../../../providers/PermissionsProvider';
import { styled } from '../../../stitches';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../utils/withZodSchema';
import { vlanSupportsFirewallRules } from '../../Firewall/utils';
import {
  FieldProvider,
  ListFieldProvider,
  MultiComboBoxFieldProvider,
  NumberFieldProvider,
} from '../../Form/FieldProvider';
import { NumberField, TextareaField, TextField, ToggleField } from '../../Form/Fields';
import { FormikConditional } from '../../FormikConditional';
import IsPermitted from '../../permissions/IsPermitted';
import {
  createVLANMutation,
  deriveDefaultDHCPIPRangeFromSubnet,
  updateVLANMutation,
  vlanFormSchema,
  vlanQuery,
  vlansQuery,
} from './utils';

const StyledForm = styled(Form, {
  display: 'contents',
  flexDirection: 'column',
  gap: '$8',
});

type CreateVLANFormValues = z.input<typeof vlanFormSchema>;

function ClientAssignmentFields({ vlan }: { vlan?: VLAN }) {
  const { values, setFieldValue, errors, touched } = useFormikContext<CreateVLANFormValues>();

  useEffect(() => {
    if (values.ipV4ClientAssignmentProtocol !== ClientAssignmentProtocol.Static) {
      setFieldValue('dhcpRule.dhcpIsEnabled', false);
    }
  }, [values.ipV4ClientAssignmentProtocol, setFieldValue]);

  useEffect(() => {
    if (
      values.ipV4ClientGateway &&
      values.ipV4ClientPrefixLength &&
      !errors.ipV4ClientGateway &&
      !errors.ipV4ClientPrefixLength &&
      (touched.ipV4ClientGateway ||
        touched.ipV4ClientPrefixLength ||
        touched.dhcpRule?.dhcpIsEnabled) &&
      !touched.dhcpRule?.startIPAddress &&
      !touched.dhcpRule?.endIPAddress
    ) {
      try {
        const [startAddress, endAddress] = deriveDefaultDHCPIPRangeFromSubnet(
          values.ipV4ClientGateway,
          values.ipV4ClientPrefixLength,
        );

        setFieldValue('dhcpRule.startIPAddress', startAddress);
        setFieldValue('dhcpRule.endIPAddress', endAddress);
      } catch (err) {
        // Likely incomplete field values, not a problem
      }
    }
  }, [
    setFieldValue,
    values.ipV4ClientGateway,
    values.ipV4ClientPrefixLength,
    errors.ipV4ClientGateway,
    errors.ipV4ClientPrefixLength,
    touched.ipV4ClientGateway,
    touched.ipV4ClientPrefixLength,
    touched.dhcpRule?.dhcpIsEnabled,
    touched.dhcpRule?.startIPAddress,
    touched.dhcpRule?.endIPAddress,
  ]);

  return (
    <FieldContainer>
      <PrimaryField
        label="IP configuration"
        element={null}
        controls={
          <FieldProvider name="ipV4ClientAssignmentProtocol">
            <CompositeField
              label="Protocol"
              element={
                <Select>
                  <SelectItem key="">None</SelectItem>
                  <SelectItem key={ClientAssignmentProtocol.Static}>Static</SelectItem>
                </Select>
              }
            />
          </FieldProvider>
        }
      />

      <FormikConditional<CreateVLANFormValues>
        condition={({ ipV4ClientAssignmentProtocol }) =>
          ipV4ClientAssignmentProtocol !== ClientAssignmentProtocol.Static
        }
      >
        <Alert
          variant="neutral"
          icon="information"
          heading="VLANs without IP addresses are layer 2 only and only available to switches"
          copy="Please add an IP address to add SSIDs, firewall rules, or other advanced features to this VLAN."
          relation="stacked"
        />
      </FormikConditional>

      <FormikConditional<CreateVLANFormValues>
        condition={({ ipV4ClientAssignmentProtocol }) =>
          ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static
        }
      >
        <FieldProvider name="ipV4ClientGateway">
          <SecondaryField
            label="IP address"
            description={
              <FormikConditional<CreateVLANFormValues> condition={({ isInternal }) => !isInternal}>
                Must be a{' '}
                <Link
                  href="https://en.wikipedia.org/wiki/Private_network#Private_IPv4_addresses"
                  target="_blank"
                >
                  private address
                </Link>
                .
              </FormikConditional>
            }
            element={<TextInput width="120px" />}
          />
        </FieldProvider>
        <NumberFieldProvider name="ipV4ClientPrefixLength" defaultValue={null}>
          <SecondaryField
            label="Prefix length"
            element={<TextInput width="40px" disabled={vlan?.isDefault} />}
          />
        </NumberFieldProvider>
      </FormikConditional>
    </FieldContainer>
  );
}

function InterVLANCommunicationField({ options }: { options: VLANQueryResult[] }) {
  const { values } = useFormikContext<CreateVLANFormValues>();

  const isEnabled = useMemo(
    () => values.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static,
    [values.ipV4ClientAssignmentProtocol],
  );

  return (
    <FieldContainer>
      <MultiComboBoxFieldProvider name="permittedInterVLANCommunicationVLANUUIDs">
        <PrimaryField
          label="Permitted inter-VLAN communication"
          description="Inter-VLAN communication is bidirectional, selecting a permitted VLAN will also add this VLAN to the other."
          element={
            <MultiComboBox placeholder="Select VLANs" disabled={!isEnabled}>
              {options.map((option) => (
                <MultiComboBoxItem key={option.UUID} textValue={`${option.name} ${option.vlanID}`}>
                  <HStack spacing={space(2)}>
                    <Text>#{option.vlanID}</Text>
                    <Text>{option.name}</Text>
                  </HStack>
                </MultiComboBoxItem>
              ))}
            </MultiComboBox>
          }
        />
      </MultiComboBoxFieldProvider>
    </FieldContainer>
  );
}

export function VLANDHCPFields({ namePrefix = '' }: { namePrefix?: string }) {
  return (
    <FieldContainer>
      <PrimaryField
        label="Enable DHCP server"
        element={null}
        controls={
          <FieldProvider name={`${namePrefix}dhcpIsEnabled`}>
            <CompositeField label="Enable DHCP" element={<ToggleInput />} />
          </FieldProvider>
        }
      />
      <FormikConditional<CreateVLANFormValues>
        condition={(values) => !!get(values, `${namePrefix}dhcpIsEnabled`)}
      >
        <FieldProvider name={`${namePrefix}startIPAddress`}>
          <SecondaryField label="Start IP address" element={<TextInput width="120px" />} />
        </FieldProvider>
        <FieldProvider name={`${namePrefix}endIPAddress`}>
          <SecondaryField label="End IP address" element={<TextInput width="120px" />} />
        </FieldProvider>
        <NumberFieldProvider name={`${namePrefix}leaseDurationHours`} defaultValue={null}>
          <SecondaryField label="Lease duration (hours)" element={<TextInput width="5rem" />} />
        </NumberFieldProvider>
        <ListFieldProvider name={`${namePrefix}dnsSearchDomains`} defaultValue={null}>
          <SecondaryField
            description="One domain per line."
            label="Search domains"
            element={<Textarea />}
          />
        </ListFieldProvider>
      </FormikConditional>
    </FieldContainer>
  );
}

export function VLANDNSFields({
  rule,
  namePrefix = '',
}: {
  rule: DHCPRule | null | undefined;
  namePrefix?: string;
}) {
  const { values } = useFormikContext();
  const featureFlags = useFeatureFlags();
  const { hasPermission } = usePermissions();
  const canVLANWriteRestricted = hasPermission(PermissionType.PermVlanWriteRestricted);

  return (
    <FieldContainer>
      <PrimaryField label="DNS server" element={null} />
      <FieldProvider name={`${namePrefix}dnsUseGatewayProxy`}>
        <SecondaryField
          label="Use gateway as DNS server"
          element={<ToggleInput disabled={!!rule?.applicationDNSFirewallRules?.length} />}
          description={
            rule?.applicationDNSFirewallRules?.length
              ? 'This VLAN has DNS security enabled, which requires using gateway as DNS server.'
              : undefined
          }
        />
      </FieldProvider>
      <ListFieldProvider name={`${namePrefix}dnsUpstreamServers`} defaultValue={[]}>
        <SecondaryField
          description="One domain per line."
          label={
            get(values, `${namePrefix}dnsUseGatewayProxy`)
              ? 'Upstream DNS servers'
              : 'Custom DNS servers'
          }
          element={<Textarea />}
        />
      </ListFieldProvider>

      <IsPermitted
        isPermitted={({ isOperator: permissionOperator, permissions, ldFlags }) =>
          permissions.hasPermission(PermissionType.PermVlanWriteRestricted) &&
          (permissionOperator || !!ldFlags['edit-vlan'])
        }
      >
        <FieldProvider name={`${namePrefix}dnsCacheIsEnabled`}>
          <SecondaryField
            label="Cache enabled"
            element={<ToggleInput />}
            internal={canVLANWriteRestricted && !featureFlags['edit-vlan']}
          />
        </FieldProvider>
        <FormikConditional<CreateVLANFormValues>
          condition={(v) => !!get(v, `${namePrefix}dnsCacheIsEnabled`)}
        >
          <NumberFieldProvider name={`${namePrefix}dnsCacheSize`} defaultValue={0}>
            <SecondaryField
              label="Cache size"
              element={<TextInput width="5rem" />}
              description="Must be at least 1024. Will be rounded down to the nearest multiple of 256."
              internal={canVLANWriteRestricted && !featureFlags['edit-vlan']}
            />
          </NumberFieldProvider>
          <NumberFieldProvider name={`${namePrefix}dnsCacheMaxTTL`} defaultValue={0}>
            <SecondaryField
              label="Cache max TTL (seconds)"
              element={<TextInput width="5rem" />}
              internal={canVLANWriteRestricted && !featureFlags['edit-vlan']}
            />
          </NumberFieldProvider>
        </FormikConditional>
      </IsPermitted>
    </FieldContainer>
  );
}

export default function VLANForm({ vlan }: { vlan?: VLAN }) {
  const companyName = useCurrentCompany();
  const network = useNetwork();
  const isCOS2Enabled = useNosFeatureEnabled(NosFeature.COS2);
  const isSOSEnabled = useNosFeatureEnabled(NosFeature.SOS);
  const navigate = useNavigate();

  const vlans = useGraphQL(vlansQuery, { networkUUID: network.UUID }).data?.vlans;
  expectDefinedOrThrow(vlans, new ResourceNotFoundError('Unable to load VLANs'));

  const usedVLANIDs = useMemo(() => new Set(vlans.map((v) => v.vlanID)), [vlans]);

  const firstAvailableVLANID = useMemo(() => {
    for (let i = 2; i <= MAX_VLAN_ID; i += 1) {
      if (!usedVLANIDs.has(i)) return i;
    }

    // If someone has 4094 VLANs, they cannot create a new one. Trying to submit with -1 will hit
    // validation and do the right thing here.
    return -1;
  }, [usedVLANIDs]);

  const interVLANOptions = useMemo(
    () =>
      vlans
        ?.filter((v) => vlanSupportsFirewallRules(v) && v.UUID !== vlan?.UUID)
        .sort((a, b) => a.vlanID - b.vlanID) ?? [],
    [vlans, vlan?.UUID],
  );

  const createMutation = useGraphQLMutation(createVLANMutation);
  const updateMutation = useGraphQLMutation(updateVLANMutation);
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    ({
      ipV4ClientAssignmentProtocol,
      ipV4ClientGateway,
      ipV4ClientPrefixLength,
      vlanID,
      dhcpRule: { dhcpIsEnabled, leaseDurationHours, ...dhcpRule },
      permittedInterVLANCommunicationVLANUUIDs,
      ...values
    }: CreateVLANFormValues) => {
      if (vlan?.UUID) {
        const input: UpdateVlanInput = values;
        if (ipV4ClientAssignmentProtocol) {
          input.ipV4ClientAssignmentProtocol = ipV4ClientAssignmentProtocol;
          if (ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
            input.ipV4ClientGateway = ipV4ClientGateway;
            if (ipV4ClientPrefixLength != null && typeof ipV4ClientPrefixLength === 'number') {
              input.ipV4ClientPrefixLength = ipV4ClientPrefixLength;
            } else {
              // shouldn't happen, this will be caught by validation
              input.ipV4ClientPrefixLength = null;
            }
          } else {
            input.ipV4ClientGateway = null;
            input.ipV4ClientPrefixLength = null;
          }
        } else {
          input.ipV4ClientAssignmentProtocol = null;
          input.ipV4ClientGateway = null;
          input.ipV4ClientPrefixLength = null;
        }

        if (!vlan.isDefault) {
          input.vlanID = vlanID;
        }

        if (
          ipV4ClientGateway &&
          ipV4ClientPrefixLength != null &&
          typeof ipV4ClientPrefixLength === 'number'
        ) {
          if (dhcpIsEnabled) {
            if (vlan.dhcpRule) {
              input.updateDHCPRules = [
                {
                  dhcpRuleUUID: vlan.dhcpRule.UUID,
                  input: {
                    ...dhcpRule,
                    leaseDurationSeconds: Math.round(leaseDurationHours * 3600),
                    gatewayIPAddress: ipV4ClientGateway,
                    gatewayPrefixLength: ipV4ClientPrefixLength,
                  },
                },
              ];
            } else {
              input.createDHCPRules = [
                {
                  ...dhcpRule,
                  leaseDurationSeconds: Math.round(leaseDurationHours * 3600),
                  gatewayIPAddress: ipV4ClientGateway,
                  gatewayPrefixLength: ipV4ClientPrefixLength,
                },
              ];
            }
          } else if (vlan.dhcpRule) {
            input.deleteDHCPRules = [vlan.dhcpRule.UUID];
          }
          input.permittedInterVLANCommunicationVLANUUIDs = permittedInterVLANCommunicationVLANUUIDs;
        } else {
          input.permittedInterVLANCommunicationVLANUUIDs = [];
        }

        updateMutation.mutate(
          { uuid: vlan?.UUID, input },
          {
            onSuccess: () => {
              notify('Successfully updated VLAN', { variant: 'positive' });
              queryClient.invalidateQueries(
                makeQueryKey(vlansQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(makeQueryKey(vlanQuery, { uuid: vlan.UUID }));
            },
            onError: (err) => {
              notify(
                `There was an error updating this VLAN${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      } else {
        const input: CreateVlanInput = { ...values, vlanID };
        if (ipV4ClientAssignmentProtocol) {
          input.ipV4ClientAssignmentProtocol = ipV4ClientAssignmentProtocol;
          if (ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static) {
            input.ipV4ClientGateway = ipV4ClientGateway;
            if (ipV4ClientPrefixLength != null && typeof ipV4ClientPrefixLength === 'number') {
              input.ipV4ClientPrefixLength = ipV4ClientPrefixLength;
              input.permittedInterVLANCommunicationVLANUUIDs =
                permittedInterVLANCommunicationVLANUUIDs;
            } else {
              // shouldn't happen, this will be caught by validation
              input.ipV4ClientPrefixLength = null;
            }
          }
        } else {
          input.ipV4ClientAssignmentProtocol = null;
          input.ipV4ClientGateway = null;
          input.ipV4ClientPrefixLength = null;
        }

        if (dhcpIsEnabled && ipV4ClientGateway && ipV4ClientPrefixLength != null) {
          input.createDHCPRules = [
            {
              ...dhcpRule,
              leaseDurationSeconds: Math.round(leaseDurationHours * 3600),
              gatewayIPAddress: ipV4ClientGateway,
              gatewayPrefixLength:
                ipV4ClientPrefixLength != null && typeof ipV4ClientPrefixLength === 'number'
                  ? ipV4ClientPrefixLength
                  : -1, // shouldn't happen, this will be caught by validation
            },
          ];
        }

        createMutation.mutate(
          { networkUUID: network.UUID, input },
          {
            onSuccess: (result) => {
              notify('Successfully created VLAN', { variant: 'positive' });
              queryClient.invalidateQueries(
                makeQueryKey(vlansQuery, { networkUUID: network.UUID }),
              );
              queryClient.invalidateQueries(
                makeQueryKey(vlanQuery, { uuid: result.createVLAN.UUID }),
              );
              navigate(
                makeDrawerLink(window.location, paths.drawers.VLANEditPage, {
                  companyName,
                  networkSlug: network.slug,
                  uuid: result.createVLAN.UUID,
                }),
              );
            },
            onError: (err) => {
              notify(
                `There was an error creating this VLAN${getGraphQLErrorMessageOrEmpty(err)}.`,
                {
                  variant: 'negative',
                },
              );
            },
          },
        );
      }
    },
    [
      companyName,
      network.slug,
      navigate,
      queryClient,
      network.UUID,
      vlan?.isDefault,
      vlan?.UUID,
      vlan?.dhcpRule,
      createMutation,
      updateMutation,
    ],
  );

  const closeDrawer = useCloseDrawerCallback();

  const formikValidator = withZodSchema(vlanFormSchema);
  const defaultIPV4ClientPrefixLength = vlan?.isDefault ? 16 : 24;

  return (
    <Formik<CreateVLANFormValues>
      validate={(values) => {
        const validation = formikValidator(values);

        if (vlan?.vlanID !== values.vlanID && usedVLANIDs.has(values.vlanID)) {
          validation.vlanID = `VLAN ID ${values.vlanID} is already in use, please select another.`;
        }

        if (
          !values.dhcpRule.dnsUseGatewayProxy &&
          vlan?.dhcpRule?.applicationDNSFirewallRules?.length
        ) {
          // Nested fields are stringified like this
          const key = 'dhcpRule.dnsUseGatewayProxy' as keyof typeof validation;
          validation[key] =
            'DNS security is enabled for this VLAN, which requires using the gateway as DNS server.';
        }

        return validation;
      }}
      initialValues={{
        isEnabled: vlan?.isEnabled ?? true,
        isInternal: vlan?.isInternal ?? false,
        name: vlan?.name ?? '',
        description: vlan?.description ?? '',
        vlanID: vlan?.vlanID ?? firstAvailableVLANID,
        ipV4ClientAssignmentProtocol: vlan
          ? vlan.ipV4ClientAssignmentProtocol ?? ''
          : ClientAssignmentProtocol.Static,
        ipV4ClientGateway: vlan?.ipV4ClientGateway ?? '',
        ipV4ClientPrefixLength: vlan?.ipV4ClientPrefixLength ?? defaultIPV4ClientPrefixLength,

        permittedInterVLANCommunicationVLANUUIDs:
          vlan?.permittedInterVLANCommunicationVLANs?.map(({ UUID }) => UUID) ?? [],

        dhcpRule: {
          dhcpIsEnabled: vlan ? !!vlan.dhcpRule : false,
          isIPv6: vlan?.dhcpRule?.isIPv6 ?? false,
          startIPAddress: vlan?.dhcpRule?.startIPAddress ?? '',
          endIPAddress: vlan?.dhcpRule?.endIPAddress ?? '',
          leaseDurationHours: vlan?.dhcpRule?.leaseDurationSeconds
            ? vlan.dhcpRule.leaseDurationSeconds / 3600
            : 24,
          dnsSearchDomains: vlan?.dhcpRule?.dnsSearchDomains ?? undefined,

          dnsUseGatewayProxy: vlan?.dhcpRule?.dnsUseGatewayProxy ?? true,
          dnsUpstreamServers: vlan?.dhcpRule?.dnsUpstreamServers?.length
            ? (vlan.dhcpRule.dnsUpstreamServers as [string, ...string[]])
            : [...DEFAULT_DNS_UPSTREAM_SERVERS],
          dnsCacheIsEnabled: vlan?.dhcpRule?.dnsCacheIsEnabled ?? true,
          dnsCacheSize: vlan?.dhcpRule?.dnsCacheSize ?? 10240,
          dnsCacheMaxTTL: vlan?.dhcpRule?.dnsCacheMaxTTL ?? 3600,
        },
      }}
      onSubmit={handleSubmit}
    >
      <StyledForm>
        <DrawerContent>
          <ToggleField name="isEnabled" label="Enable" />

          <IsPermitted
            isPermitted={({ permissions }) =>
              permissions.hasPermission(PermissionType.PermVlanWriteRestricted)
            }
          >
            <ToggleField name="isInternal" label="Read-only" internal />
          </IsPermitted>

          <TextField name="name" label="Name" />
          <TextareaField optional name="description" label="Description" />
          <NumberField label="VLAN ID" name="vlanID" />

          <ClientAssignmentFields vlan={vlan} />

          <FormikConditional<CreateVLANFormValues>
            condition={(values) =>
              values.ipV4ClientAssignmentProtocol === ClientAssignmentProtocol.Static
            }
          >
            <InterVLANCommunicationField options={interVLANOptions} />

            <VLANDHCPFields namePrefix="dhcpRule." />

            <FormikConditional<CreateVLANFormValues>
              condition={(values) => values.dhcpRule.dhcpIsEnabled}
            >
              <VLANDNSFields rule={vlan?.dhcpRule} namePrefix="dhcpRule." />
            </FormikConditional>
          </FormikConditional>

          {(!isCOS2Enabled || !isSOSEnabled) && !!vlan && (
            <FormikConditional condition={(_, f) => f.dirty}>
              <Alert
                type="inline"
                variant="alternative"
                icon="warning"
                heading="Be mindful of these changes"
                copy={`Please make sure any VLANs you modify are in sync with the config in ${[
                  !isCOS2Enabled ? 'NOC' : null,
                  !isSOSEnabled ? 'any non-Meter switches' : null,
                ]
                  .filter(Boolean)
                  .join(' and ')}.`}
                internal
              />
            </FormikConditional>
          )}
        </DrawerContent>
        <DrawerFooter
          actions={
            <>
              <Button type="button" onClick={closeDrawer} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">{vlan ? 'Save' : 'Add'}</Button>
            </>
          }
        />
      </StyledForm>
    </Formik>
  );
}
