import type { ManufacturerIconName } from '@meterup/atto';
import { getManufacturerIconName, lookupMACAddressOUI } from '@meterup/common';
import { isAfter, isValid } from 'date-fns';

import type { AccessPointConnectedClient } from '../components/Wireless/utils';
import type { NetworkClient } from '../hooks/networkClients/useNetworkClients';
import { RadioBand, ServiceType } from '../gql/graphql';

export type ConnectionStatus = 'online' | 'offline' | 'wired';

export const isWired = (c: NetworkClient) => !c.isWireless;

export const isWireless = (c: NetworkClient) => c.isWireless;

export const isPrinter = (c: NetworkClient) =>
  c.serviceTypes?.includes(ServiceType.Printer) ?? false;

export const isAirplay = (c: NetworkClient) =>
  c.serviceTypes?.includes(ServiceType.Airplay) ?? false;

export const isSpeaker = (c: NetworkClient) =>
  c.serviceTypes?.includes(ServiceType.Speaker) ?? false;

export const clientManufacturer = (
  c: NetworkClient | AccessPointConnectedClient,
  ouiData: Record<string, string> | undefined,
) => lookupMACAddressOUI(c.macAddress, ouiData) ?? undefined;

export const clientNameOrNull = (c: Pick<NetworkClient, 'alias' | 'clientName'>) =>
  (c.alias || (c.clientName !== '*' ? c.clientName : null)) ?? null;

export const isLastSeenKnown = (c: NetworkClient) => {
  if (c.lastSeen) {
    const date = new Date(c.lastSeen);
    return isValid(date) && isAfter(date, new Date(2010, 1, 1));
  }

  return false;
};

export const isOnline = (c: NetworkClient): boolean => {
  if (c.lastSeen) {
    const dateLessFive = new Date();
    const itemDate = new Date(c.lastSeen);

    dateLessFive.setMinutes(dateLessFive.getMinutes() - 5);

    return itemDate >= dateLessFive;
  }

  return false;
};

export const getClientConnectionStatus = (client: NetworkClient): ConnectionStatus => {
  if (isWireless(client)) {
    return isOnline(client) ? 'online' : 'offline';
  }

  return 'wired';
};

export const manufacturerIconName = (
  client: NetworkClient | AccessPointConnectedClient,
  ouiData: Record<string, string> | undefined,
): ManufacturerIconName => getManufacturerIconName(clientManufacturer(client, ouiData) ?? '');

export const getClientSortKey = (row: NetworkClient) =>
  (row.alias ? `${row.alias} (${row.clientName})` : row.clientName) ?? '';

// Format bytes to megabytes for tx/rx usage
export const formatUsage = (number: number) => (number / 1000000).toFixed(2);

// Sorts in asc order with "*" at the end
export const defaultSort = (a: NetworkClient, b: NetworkClient) => {
  const aName = a.clientName ?? '*';
  const bName = b.clientName ?? '*';
  if (aName === '*' && bName === '*') {
    return 0;
  }
  if (aName === '*') {
    return 1;
  }
  if (bName === '*') {
    return -1;
  }
  return aName.localeCompare(bName);
};

export const formatRadioBand = (band: RadioBand | null | undefined) => {
  switch (band) {
    case RadioBand.Band_2_4G:
      return '2.4 GHz';
    case RadioBand.Band_5G:
      return '5 GHz';
    default:
      return '';
  }
};
